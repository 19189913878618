import React, { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import logo from "../assets/images/Web-Application-Logo.svg";
// import setting_icon_active from "../assets/images/sidebar/icons/setting_active.svg";

const Privacypolicy = () => {

    const {
  brand, flagForBrand
    



  
      } = useContext(AppContext);
  return (
    <div className="box-login" style={{ display: 'block', padding:"15px 50px" }}>
      <div className="top-logo">
   

<img src={ brand?.logo || logo
                  }
                  style={{
                    opacity : flagForBrand ? "0" : "100%",
                   
                  }}
                   alt="" />
      </div>
      <hr />

      <h2>Privacy Policy</h2>
      <p>
        Trackschoolbus built the Driver console app as a free app. This SERVICE is provided by Trackschoolbus at no cost and is intended
        for use as is.
      </p>
      <p>
        This page is used to inform website visitors regarding our policies with the collection, use, and
        disclosure of Personal Information if anyone decided to use our Service.
      </p>
      <p>
        If you choose to use our Service, then you agree to the collection and use of information in
        relation with this policy. The Personal Information that we collect are used for providing and
        improving the Service. We will not use or share your information with anyone except as described
        in this Privacy Policy.
      </p>
      <p>
        The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions,
        which is accessible at Driver console, unless otherwise defined in this Privacy Policy.
 

     </p>

      <p>
        <strong>Information Collection and Use</strong>
      </p>
      <p>
        For a better experience while using our Service, we may require you to provide us with certain
        personally identifiable information, including but not limited to add whatever else you collect here, IMEI Number, Images, Audio,
        Location. The information that we request will be retained by us and used as described in this privacy policy.
      </p>
      <p>The app does use third-party services that may collect information used to identify you.</p>

      <p>
        <strong>Log Data</strong>
      </p>
      <p>We didn't use any Log Data related data of your device.</p>

      <p>
        <strong>Children’s Privacy</strong>
      </p>
      <p>
        These Services do not address anyone under the age of 18. We do not knowingly collect personal
        identifiable information from children under 18. In the case we discover that a child under 18
        has provided us with personal information, we immediately delete this from our servers. If you
        are a parent or guardian and you are aware that your child has provided us with personal
        information, please contact us so that we will be able to take necessary actions.
      </p>

      <p>
        <strong>Changes to This Privacy Policy</strong>
      </p>
      <p>
        We may update our Privacy Policy from time to time. Thus, you are advised to review this page
        periodically for any changes. We will notify you of any changes by posting the new Privacy Policy
        on this page. These changes are effective immediately, after they are posted on this page.
      </p>

      <p>
        <strong>Contact Us</strong>
      </p>
      <p>
        If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact
        us at info@vehicletracking.qa.
      </p>

      <div className="copyright">
        © Powered by Vehicle Tracking
      </div>
    </div>
  );
};

export default Privacypolicy;
